import { FC } from 'react';
import { ConditionalRender, MenuItem, MenuList, Typography } from '@egym/ui';
import { PartnerConfig } from '@types';

type Props = {
  onClose: () => void;
  configs: PartnerConfig[];
  selectedConfigId?: number;
  onConfigSelect: (id: number) => void;
};

const PartnerConfigsSelectList: FC<Props> = ({ configs, selectedConfigId, onConfigSelect, onClose }) => {
  return (
    <MenuList
      sx={{
        maxHeight: '200px',
        width: '100%',
        overflow: 'auto',
        p: 0,
        textAlign: 'center',
      }}
      data-testid="sub-header-partner-configs-list"
    >
      <ConditionalRender condition={Boolean(configs.length)}>
        <>
          {configs.map(({ id, name }) => {
            return (
              <MenuItem
                selected={selectedConfigId === id}
                key={name}
                onClick={() => {
                  onConfigSelect(id);
                  onClose();
                }}
                data-testid={`sub-header-partner-configs-item-${id}`}
                data-test-selected={selectedConfigId === id}
              >
                <Typography variant="body2Medium">{name}</Typography>
              </MenuItem>
            );
          })}
        </>
      </ConditionalRender>
    </MenuList>
  );
};

export default PartnerConfigsSelectList;
