import { FC, useCallback, useState, cloneElement } from 'react';
import { useTimeoutFn } from 'react-use';
import { ClickAwayListener, TooltipProps, Tooltip } from '@egym/ui';

type Props = Omit<
  TooltipProps,
  'open' | 'onClose' | 'onClick' | 'disableFocusListener' | 'disableHoverListener' | 'disableTouchListener' | 'sx'
> & {
  onClick: () => void;
};

export const ConditionTooltip: FC<Props> = ({ onClick, children, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const [isReady, cancel, reset] = useTimeoutFn(() => setOpen(false), 3000);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
    if (isReady() === false) {
      cancel();
    }
    if (isReady() === null) {
      reset();
    }
  }, [cancel, isReady, reset]);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
    if (isReady()) {
      reset();
    }
    onClick();
  }, [onClick, isReady, reset]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          {...rest}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {cloneElement(children, { onClick: handleTooltipOpen })}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
