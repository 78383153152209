import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { SelectFormField, Stack } from '@egym/ui';
import { CreateUseCaseFormProps } from './CreateUseCaseFormProps';

const CreateUseCaseForm: FC<CreateUseCaseFormProps> = ({ integrationTypeOptions, useCaseNameOptions }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={5} data-testid="create-usecase-form">
      <Stack flexBasis="50%">
        <Field
          id="type"
          name="type"
          options={integrationTypeOptions}
          component={SelectFormField}
          label="common.label.type"
          placeholder={`${t('integration.title')} ${t('common.label.type')}`}
          hiddenLabel
          data-testid="usecase-type-field"
        />
      </Stack>
      <Stack flexBasis="50%">
        <Field
          id="name"
          name="name"
          options={useCaseNameOptions}
          component={SelectFormField}
          disabled={!useCaseNameOptions.length}
          label="usecase.title"
          placeholder={`${t('integration.title')} ${t('usecase.title')}`}
          hiddenLabel
          data-testid="usecase-name-field"
        />
      </Stack>
    </Stack>
  );
};

export default CreateUseCaseForm;
