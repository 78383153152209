import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@egym/ui';
import { TestCaseFieldResult } from '@types';
import { columns } from './columns';

type Props = {
  actualData: Record<string, TestCaseFieldResult>;
};

const TestCaseResultDetails: FC<Props> = ({ actualData }) => {
  const { t } = useTranslation();

  const rows = useMemo(
    () =>
      Object.entries(actualData)
        .map(([key, matcher]) => ({
          id: key,
          condition: t(`testcase.matchers.${matcher.type}`),
          expectedValue: matcher.expectedValue,
          actualValue: matcher.actualValue,
          matches: matcher.matches,
        }))
        .sort((a, b) => a.id.localeCompare(b.id)),
    [actualData, t],
  );

  return <Table rows={rows} columns={columns} prefixTestId="test-case-results" />;
};

export default TestCaseResultDetails;
