import {
  CORPORATE_FITNESS_PERMISSION_NAME,
  EGYM_PERMISSION_NAME,
  MEASUREMENTS_READ_PERMISSION_NAME,
  PUSH_NOTIFICATIONS_PERMISSION_NAME,
  RFID_PERMISSION_NAME,
  RFID_REVERSE_PERMISSION_NAME,
  WORKOUTS_PERMISSION_NAME,
} from '@constants';

export const groupPermissions = (permissions: string[]) => {
  const result: {
    key: string;
    title: string;
    items: {
      key: string;
      title: string;
      value: boolean;
    }[];
  }[] = [];
  if (permissions.includes(EGYM_PERMISSION_NAME)) {
    result.push({
      key: 'egym',
      title: 'common.label.egym',
      items: [
        {
          key: 'syncEgymMember',
          title: 'locations.label.apiPermissions.syncEgymMember',
          value: true,
        },
      ],
    });
  }
  if (permissions.includes(RFID_PERMISSION_NAME)) {
    result.push({
      key: 'rfid',
      title: 'locations.label.rfid',
      items: [
        {
          key: 'syncRfid',
          title: 'locations.label.apiPermissions.syncRfid',
          value: true,
        },
        {
          key: 'reverseRfid',
          title: 'locations.label.apiPermissions.reverseRfid',
          value: permissions.includes(RFID_REVERSE_PERMISSION_NAME),
        },
      ],
    });
  }
  if (permissions.includes(CORPORATE_FITNESS_PERMISSION_NAME)) {
    result.push({
      key: 'wellpass',
      title: 'locations.label.wellpass',
      items: [
        {
          key: 'syncMembers',
          title: 'locations.label.apiPermissions.syncWellpassMember',
          value: true,
        },
      ],
    });
  }
  if (permissions.includes(WORKOUTS_PERMISSION_NAME)) {
    result.push({
      key: 'workouts',
      title: 'locations.label.workouts',
      items: [
        {
          key: 'syncWorkouts',
          title: 'locations.label.apiPermissions.syncWorkouts',
          value: true,
        },
      ],
    });
  }
  if (permissions.includes(MEASUREMENTS_READ_PERMISSION_NAME)) {
    result.push({
      key: 'measurements',
      title: 'locations.label.measurements',
      items: [
        {
          key: 'syncMeasurements',
          title: 'locations.label.apiPermissions.syncMeasurements',
          value: true,
        },
      ],
    });
  }
  if (permissions.includes(PUSH_NOTIFICATIONS_PERMISSION_NAME)) {
    result.push({
      key: 'pushNotifications',
      title: 'locations.label.pushNotifications',
      items: [
        {
          key: 'syncPushNotifications',
          title: 'locations.label.apiPermissions.syncPushNotifications',
          value: true,
        },
      ],
    });
  }

  return result;
};
