import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IMaskInput } from 'react-imask';
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { ConditionalRender } from '../../utils';

export type TextFieldProps = MuiTextFieldProps;

const TextMaskCustom = React.forwardRef<
  HTMLElement,
  { onChange: (event: { target: { name: string; value: string } }) => void; name: string }
>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const TextField: React.FC<TextFieldProps> = ({ inputProps, InputProps, ...props }) => {
  return (
    <ConditionalRender condition={Boolean(inputProps?.mask)}>
      <MuiTextField
        {...props}
        inputProps={inputProps}
        InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}
      />
      <MuiTextField {...props} inputProps={inputProps} InputProps={InputProps} />
    </ConditionalRender>
  );
};
