import { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { lighten } from '@mui/system/colorManipulator';
import { Button, ButtonProps, buttonClasses } from '@egym/ui';

export const BlackButton = styled((props: ButtonProps & MuiLoadingButtonProps) => <Button {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.black,
    [`&.${buttonClasses.contained}`]: {
      '&:hover': {
        backgroundColor: lighten(theme.palette.common.black, 0.15),
      },
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: lighten(theme.palette.common.black, 0.7),
        color: theme.palette.common.white,
      },
    },
    [`&.${buttonClasses.outlined}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: `2px solid ${theme.palette.common.black}`,
      '&:hover': {
        backgroundColor: grey[200],
        border: `2px solid ${lighten(theme.palette.common.black, 0.3)}`,
        color: lighten(theme.palette.common.black, 0.3),
      },
      [`&.${buttonClasses.disabled}`]: {
        border: `2px solid ${lighten(theme.palette.common.black, 0.7)}`,
        color: lighten(theme.palette.common.black, 0.7),
      },
    },
  }),
);
