export enum UserRole {
  partner = 'ROLE_PARTNER',
  manager = 'ROLE_MANAGER',
}

export type PartnerConfig = {
  id: number;
  name: string;
  integrations: boolean;
  gyms: boolean;
  logoImageId?: number | null;
};

export type ModulesInfo = {
  gyms: boolean;
  sandbox: boolean;
};

export type UserInfo = {
  userId: string;
  firstName: string;
  lastName: string;
  locale: string;
  profileImageUrl?: string;
  roles: UserRole[];
  partnerConfig?: PartnerConfig;
};

export type LoginRequest = {
  email: string;
  password: string;
};
