import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackbar } from '@egym/ui';
import { postLogout } from '@api';
import { sessionState } from '@globalState';
import { UseHeaderResultProps } from '../../HeaderProps';

const useHeader = (): UseHeaderResultProps => {
  const userInfo = useRecoilValue(sessionState.atoms.userInfo);
  const moduleInfo = useRecoilValue(sessionState.atoms.moduleInfo);
  const setPartnerConfig = useSetRecoilState(sessionState.atoms.selectedPartnerConfig);
  const { openSnackbar } = useSnackbar();

  const logout = useCallback(async () => {
    try {
      await postLogout();
      setPartnerConfig(null);
      window.location.assign('/');
    } catch {
      openSnackbar('common.errors.netError', { severity: 'error' });
    }
  }, [openSnackbar, setPartnerConfig]);

  return {
    logout,
    moduleInfo,
    userInfo,
  };
};

export default useHeader;
