import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverflowTip, TableRenderCellParams } from '@egym/ui';

const OverflowTextCell = ({ value }: TableRenderCellParams) => {
  const { t } = useTranslation();
  return <OverflowTip>{value ? t(value) : (value ?? '-')}</OverflowTip>;
};

export default OverflowTextCell;
