import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, TypographyProps } from '@mui/system';
import { FieldProps } from 'formik';
import { get } from 'lodash';
import { Typography } from '@egym/ui';
import { FormFieldWithLabel } from '../FormFieldWithLabel';
import { TextField, TextFieldProps } from '../TextField';

type TextFormFieldProps = {
  label?: string;
  name: string;
  wrapperSx?: SxProps;
  labelSx?: SxProps;
  fieldSx?: SxProps;
  viewModeFieldSx?: SxProps;
  isViewMode?: boolean;
  showErrorFirst?: boolean;
  markAsOptional?: boolean;
  viewModeTypographyProps?: TypographyProps;
};

type Props = TextFieldProps & FieldProps & TextFormFieldProps;

export const TextFormField: React.FC<Props> = React.memo(
  ({
    wrapperSx,
    labelSx,
    fieldSx,
    viewModeFieldSx,
    field,
    form,
    isViewMode,
    showErrorFirst,
    placeholder,
    markAsOptional,
    viewModeTypographyProps,
    inputProps,
    helperText,
    ...props
  }) => {
    const { t } = useTranslation();
    const getByFieldName = useMemo(() => (obj: any) => get(obj, field.name), [field.name]);

    const errorText = useMemo(
      () => getByFieldName(form.touched) && getByFieldName(form.errors),
      [form.touched, form.errors, getByFieldName],
    );

    const charactersLeftCountHint = useMemo(
      () =>
        inputProps?.maxLength && inputProps.maxLength > 0
          ? t('common.hints.characterLeft', { count: inputProps.maxLength - (field.value?.length ?? 0) })
          : null,
      [t, inputProps?.maxLength, field.value],
    );

    const showErrorOverCountHint = useMemo(
      () => showErrorFirst || charactersLeftCountHint,
      [showErrorFirst, charactersLeftCountHint],
    );

    return (
      <FormFieldWithLabel
        wrapperSx={wrapperSx}
        labelSx={labelSx}
        label={props.label}
        hiddenLabel={props.hiddenLabel}
        id={props.id}
        isViewMode={isViewMode}
        markAsOptional={markAsOptional}
      >
        {({ fieldSx: labelFieldSx, viewModeFieldSx: labelViewModeFieldSx, helperTextSx: labelHelperTextSx }) => {
          const typographySx = { ...labelViewModeFieldSx, ...viewModeFieldSx };
          const textFieldSx = { ...labelFieldSx, ...fieldSx };

          return isViewMode ? (
            <Typography variant="body1" sx={typographySx} {...viewModeTypographyProps}>
              {field.value || '-'}
            </Typography>
          ) : (
            <TextField
              {...props}
              {...field}
              value={field.value || ''}
              label={props.hiddenLabel ? null : props.label}
              error={Boolean(getByFieldName(form.touched) && getByFieldName(form.errors))}
              inputProps={inputProps}
              helperText={
                showErrorOverCountHint && errorText ? errorText : (helperText ?? charactersLeftCountHint) || errorText
              }
              sx={textFieldSx}
              placeholder={placeholder ? t(placeholder) : ''}
              FormHelperTextProps={{
                sx: labelHelperTextSx,
                ...props.FormHelperTextProps,
              }}
            />
          );
        }}
      </FormFieldWithLabel>
    );
  },
);
