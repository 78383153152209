export enum TestCaseName {
  syncNewMember = 'SYNC_NEW_MEMBER',
  updateMemberData = 'UPDATE_MEMBER_DATA',
  syncProspectNewProspect = 'SYNC_NEW_PROSPECT_MEMBER',
  updateProspectToMember = 'UPDATE_PROSPECT_TO_MEMBER',
  memberImageUpload = 'MEMBER_IMAGE_UPLOAD',
  assignRfidToMember = 'ASSIGN_RFID_TO_MEMBER',
  updateMemberRfid = 'UPDATE_MEMBER_RFID',
  deleteRfidForMember = 'DELETE_RFID_FOR_MEMBER',
  deleteMemberWithData = 'DELETE_MEMBER_WITH_DATA',
  syncMemberWithAccountConflict = 'SYNC_MEMBER_WITH_ACCOUNT_CONFLICT',
  syncMemberWithMembershipConflict = 'SYNC_MEMBER_WITH_MEMBERSHIP_CONFLICT',
  updateMemberWithAccountConflict = 'UPDATE_MEMBER_WITH_ACCOUNT_CONFLICT',
  syncNewWellPassMember = 'SYNC_NEW_WELLPASS_MEMBER',
  updateBasicToCorporateMember = 'UPDATE_BASIC_TO_CORPORATE_MEMBER',
  updateCorporateToBasicMember = 'UPDATE_CORPORATE_TO_BASIC_MEMBER',
  wellPassMemberAdmissionPositive = 'WELLPASS_MEMBER_ADMISSION_POSITIVE',
  wellPassMemberAdmissionNegative = 'WELLPASS_MEMBER_ADMISSION_NEGATIVE',
  syncCheckinEvent = 'SYNC_CHECKIN_EVENT',
  syncCheckoutEvent = 'SYNC_CHECKOUT_EVENT',
  uploadMemberImage = 'UPLOAD_MEMBER_IMAGE',
  availableProducts = 'AVAILABLE_PRODUCTS',
  updateProduct = 'UPDATE_PRODUCT',
  activateProduct = 'ACTIVATE_PRODUCT',
  activateSecondProduct = 'ACTIVATE_SECOND_PRODUCT',
  deactivateProduct = 'DEACTIVATE_PRODUCT',
  updateWellpassMemberData = 'UPDATE_WELLPASS_MEMBER_DATA',
  wellpassGetExpiredAccountWithTimestamp = 'WELLPASS_GET_EXPIRED_ACCOUNT_WITH_TIMESTAMP',
  wellpassGetExpiredAccountWithError = 'WELLPASS_GET_EXPIRED_ACCOUNT_WITH_ERROR',
  wellpassUploadMemberImage = 'WELLPASS_UPLOAD_MEMBER_IMAGE',
  wellpassDeleteMemberWithData = 'WELLPASS_DELETE_MEMBER_WITH_DATA',
  wellpassSyncNewMemberInvalidTan = 'WELLPASS_SYNC_NEW_MEMBER_INVALID_TAN',
}

export enum TestCaseResult {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
}

export enum MatcherType {
  exact = 'EXACT',
  dateInFuture = 'DATE_IN_FUTURE',
  useContextValue = 'USE_CONTEXT_VALUE',
  exists = 'EXISTS',
}

export enum MatcherTarget {
  requestBody = 'REQUEST_BODY',
  responseBody = 'RESPONSE_BODY',
  queryParam = 'QUERY_PARAM',
}

export enum PreconditionType {
  context = 'CONTEXT',
  generateValue = 'GENERATE_VALUE',
  testCaseFinished = 'TEST_CASE_FINISHED',
}

export type TestCaseFieldMatcher = {
  type: MatcherType;
  value: string | null;
  target: MatcherTarget;
};

export type TestCaseFieldResult = {
  type: MatcherType;
  actualValue: string | null;
  expectedValue: string | null;
  matches: boolean;
  errorMessage?: string;
};

export type ContextPrecondition = {
  type: PreconditionType.context;
  testCaseName: TestCaseName;
  field: string;
};

export type GenerateValuePrecondition = {
  type: PreconditionType.generateValue;
  valueType: 'ACCOUNT' | 'CONFLICT_ACCOUNTS' | 'VERIFICATION_TAN' | 'WELLPASS_ACCOUNT';
  availableFields: string[];
};

export type TestCaseFinishedPrecondition = {
  type: PreconditionType.testCaseFinished;
  testCaseName: TestCaseName;
};

export type TestCase = {
  id: number;
  name: TestCaseName;
  skippable: boolean;
  preconditions?: (ContextPrecondition | GenerateValuePrecondition | TestCaseFinishedPrecondition)[];
  expectedData?: Record<string, TestCaseFieldMatcher>;
  actualData?: Record<string, TestCaseFieldResult>;
  result?: TestCaseResult;
};
