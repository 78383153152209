import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { isEmpty, omit, zipObject } from 'lodash';
import { AvailableRowsPerPage } from '@egym/ui';
import {
  GroupedTableColumn,
  TableColumn,
  TableContainerProps,
  TableRowType,
  TableState,
  UseTableResult,
} from '../TableProps';

const useTable = ({
  rows = [],
  checkboxSelectionProp,
  defaultRowsPerPage,
  columns,
  renderSubComponent,
  onSelectAllClick,
  onSelectRowClick,
  controlledSelectedRowIds,
  onTableStateChange,
  tableState: externalTableState,
  totalElements,
  prefixTestId,
}: TableContainerProps): UseTableResult => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>(controlledSelectedRowIds || []);
  const [rowsPerPage, setRowsPerPage] = useState(
    externalTableState?.pagination?.size || defaultRowsPerPage || AvailableRowsPerPage.Small,
  );
  const [page, setPage] = useState(externalTableState?.pagination?.page || 0);
  const rowsIds = useMemo(() => {
    if (!checkboxSelectionProp) return [];

    return rows.map(row => row[checkboxSelectionProp]);
  }, [rows, checkboxSelectionProp]);
  const [hasData, setHasData] = useState<boolean>(false);

  const [tableState, setTableState] = useState<TableState>(externalTableState || {});

  const tableStateResult = useMemo(() => externalTableState || tableState, [externalTableState, tableState]);

  const hasExternalPagination = useMemo(() => !isEmpty(tableStateResult.pagination), [tableStateResult.pagination]);

  const tableStateUpdater = useMemo(
    () => (onTableStateChange && externalTableState ? onTableStateChange : setTableState),
    [onTableStateChange, externalTableState],
  );

  useUpdateEffect(() => {
    if (onTableStateChange && !externalTableState) {
      onTableStateChange(tableState);
    }
  }, [tableState]);

  useUpdateEffect(() => {
    if (controlledSelectedRowIds) {
      setSelectedRowIds(controlledSelectedRowIds);
    }
  }, [controlledSelectedRowIds]);

  const rowsSelectedCount = useMemo(() => {
    const selectedRowsWithFilters = selectedRowIds.filter(selectedRowId => rowsIds.includes(selectedRowId));

    return selectedRowsWithFilters.length;
  }, [selectedRowIds, rowsIds]);

  const totalRowsCount = useMemo(() => {
    return totalElements || rows.length;
  }, [totalElements, rows]);

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!checkboxSelectionProp) return;

      if (onSelectAllClick) {
        onSelectAllClick(event.target.checked);
      } else {
        if (event.target.checked) {
          setSelectedRowIds(rows.map(row => row[checkboxSelectionProp]));
          return;
        }
        setSelectedRowIds([]);
      }
    },
    [checkboxSelectionProp, onSelectAllClick, rows],
  );

  const handleRowClick = useCallback(
    (event: MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, row: TableRowType) => {
      if (!checkboxSelectionProp) return;

      if (onSelectRowClick) {
        onSelectRowClick(event, row);
      } else {
        const prop = row[checkboxSelectionProp];

        const selectedIndex = selectedRowIds.indexOf(prop);

        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedRowIds, prop);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedRowIds.slice(1));
        } else if (selectedIndex === selectedRowIds.length - 1) {
          newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedRowIds.slice(0, selectedIndex),
            selectedRowIds.slice(selectedIndex + 1),
          );
        }

        setSelectedRowIds(newSelected);
      }
    },
    [checkboxSelectionProp, onSelectRowClick, selectedRowIds],
  );

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (hasExternalPagination) {
        tableStateUpdater(prevState => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            page: newPage,
          },
        }));
      } else {
        setPage(newPage);
      }
    },
    [hasExternalPagination, tableStateUpdater],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (hasExternalPagination) {
        tableStateUpdater(prevState => ({
          ...prevState,
          pagination: {
            size: parseInt(event.target.value, 10),
            page: 0,
          },
        }));
      } else {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      }
    },
    [hasExternalPagination, tableStateUpdater],
  );

  const rowsPerPageResult = useMemo(
    () => (hasExternalPagination && tableStateResult.pagination?.size) || rowsPerPage,
    [hasExternalPagination, rowsPerPage, tableStateResult.pagination?.size],
  );

  const pageResult = useMemo(
    () => (hasExternalPagination && tableStateResult.pagination?.page) || page,
    [hasExternalPagination, page, tableStateResult.pagination?.page],
  );

  const rowsPaginated = useMemo(() => {
    if (hasExternalPagination) {
      return rows;
    }

    return rows.slice(pageResult * rowsPerPageResult, pageResult * rowsPerPageResult + rowsPerPageResult);
  }, [rows, pageResult, rowsPerPageResult, hasExternalPagination]);

  useEffect(() => {
    if (rowsPaginated.length && !hasData) {
      setHasData(true);
    }
  }, [rowsPaginated, hasData]);

  const totalColumnsCount = useMemo(() => {
    let res = columns.length;

    if (checkboxSelectionProp) res += 1;
    if (renderSubComponent) res += 1;

    return res;
  }, [columns, checkboxSelectionProp, renderSubComponent]);

  const isSelectAllChecked = useMemo(() => {
    return totalRowsCount > 0 && rowsSelectedCount === totalRowsCount;
  }, [totalRowsCount, rowsSelectedCount]);

  const isSelectAllIndeterminate = useMemo(() => {
    return rowsSelectedCount > 0 && rowsSelectedCount < totalRowsCount;
  }, [rowsSelectedCount, totalRowsCount]);

  const updateTableStateSorting = useCallback(
    fieldName => {
      tableStateUpdater(prevState => ({
        ...prevState,
        sorting: {
          [fieldName]: prevState.sorting?.[fieldName] === 'desc' ? 'asc' : 'desc',
        },
      }));
    },
    [tableStateUpdater],
  );

  const updateTableStateFilters = useCallback(
    (fieldNames, value) => {
      tableStateUpdater(prevState => {
        if (!value) {
          return {
            ...prevState,
            filtering: omit(prevState.filtering, fieldNames),
          };
        }

        if (fieldNames.length > 1) {
          return {
            ...prevState,
            pagination: {
              page: 0,
              size: prevState?.pagination?.size,
            },
            filtering: {
              ...prevState.filtering,
              ...zipObject(value, fieldNames),
            },
          };
        }
        return {
          ...prevState,
          pagination: {
            page: 0,
            size: prevState?.pagination?.size,
          },
          filtering: {
            ...prevState.filtering,
            [fieldNames[0]]: value,
          },
        };
      });
    },
    [tableStateUpdater],
  );

  const hasFilterableColumns = useMemo(() => {
    return columns
      .flatMap(column => (column as GroupedTableColumn).children || [column as TableColumn])
      .some(column => column.filter);
  }, [columns]);

  const hasSelectedFilters = useMemo(
    () => Boolean(tableStateResult.filtering) && !isEmpty(tableStateResult.filtering),
    [tableStateResult],
  );

  return {
    handleSelectAllClick,
    handleRowClick,
    selectedRowIds,
    rowsSelectedCount,
    totalRowsCount,
    rowsPerPage: rowsPerPageResult,
    page: pageResult,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPaginated,
    totalColumnsCount,
    isSelectAllChecked,
    isSelectAllIndeterminate,
    tableState: externalTableState || tableState,
    updateTableStateSorting,
    updateTableStateFilters,
    hasExternalPagination,
    hasFilterableColumns,
    hasSelectedFilters,
    hasData,
    testIdPrefix: prefixTestId ? `${prefixTestId}-` : '',
  };
};

export default useTable;
