import { atom, selector } from 'recoil';
import { PaginationQueryParams } from '@egym/api';
import { TableState } from '@egym/ui';

const keys = {
  table: 'gymListTable.table',
  queryParams: 'gymListTable.queryParams',
};

const atoms = {
  table: atom<TableState>({
    key: keys.table,
    default: {
      pagination: {
        size: 10,
        page: 0,
      },
      sorting: {
        lastActive: 'desc',
      },
    },
  }),
};

const selectors = {
  queryParams: selector<PaginationQueryParams>({
    key: keys.queryParams,
    get: ({ get }) => {
      const tableObj = get(atoms.table);
      return {
        sort: tableObj.sorting
          ? Object.entries(tableObj.sorting).map(([field, direction]) => `${field},${direction}`)
          : undefined,
        page: tableObj.pagination?.page,
        size: tableObj.pagination?.size,
        ...tableObj.filtering,
      };
    },
  }),
};

export const gymListTable = {
  keys,
  atoms,
  selectors,
};
