import { last } from 'lodash';
import { Icons, TableColumn, Tooltip } from '@egym/ui';

export const columns: TableColumn[] = [
  {
    field: 'id',
    headerTitle: 'common.label.field',
    bodyCellSx: ({ row }) => ({
      minWidth: '100px',
      height: '50px',
      bgcolor: row.matches ? 'white' : 'tableErrorRow.main',
      borderBottom: 'none',
    }),
    width: 100,
    testIdAccessor: ({ value }) => value,
    accessor: ({ value }) => (value?.includes('.') ? last(value.split('.')) : value),
  },
  {
    field: 'expectedValue',
    headerTitle: 'common.label.result.expected_plural',
    bodyCellSx: ({ row }) => ({
      minWidth: '150px',
      height: '50px',
      bgcolor: row.matches ? 'white' : 'tableErrorRow.main',
      borderBottom: 'none',
    }),
    width: 150,
    testIdAccessor: ({ value }) => value,
  },
  {
    field: 'actualValue',
    headerTitle: 'common.label.result.actual_plural',
    bodyCellSx: ({ row }) => ({
      minWidth: '150px',
      height: '50px',
      bgcolor: row.matches ? 'white' : 'tableErrorRow.main',
      borderBottom: 'none',
    }),
    width: 150,
    testIdAccessor: ({ value }) => value,
  },
  {
    field: 'hint',
    headerTitle: 'common.label.hint',
    bodyCellSx: ({ row }) => ({
      minWidth: '50px',
      height: '50px',
      bgcolor: row.matches ? 'white' : 'tableErrorRow.main',
      borderBottom: 'none',
    }),
    width: 50,
    renderCell: ({ row }) =>
      !row.matches ? (
        <Tooltip title={row.condition}>
          <Icons.Warning color="error" data-testid={`test-case-result-failed-icon-${row.id}`} />
        </Tooltip>
      ) : null,
    testIdAccessor: ({ row }) => row.matches,
  },
];
