import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell as MuiTableCell, TableSortLabel } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ConditionalRender, TableColumn, TableState, Typography, Tooltip, Icons, Stack } from '@egym/ui';

type Props = {
  column: TableColumn;
  headersCellSx?: SystemStyleObject;
  tableState: TableState;
  updateTableStateSorting: (fieldName: string) => void;
  testIdPrefix: string;
};

const ThColumnCell: React.FC<Props> = ({
  column,
  headersCellSx,
  tableState,
  updateTableStateSorting,
  testIdPrefix,
}) => {
  const { t } = useTranslation();
  return (
    <MuiTableCell
      variant="head"
      component="th"
      key={column.field}
      align={column.numeric ? 'right' : 'left'}
      padding={column.disablePadding ? 'none' : 'normal'}
      width={column.width}
      sx={{ py: 5.5, ...headersCellSx, ...column.headerCellSx }}
      data-testid={`${testIdPrefix}table-header-cell-${column.field}`}
    >
      <Stack direction="row" alignItems="center">
        <ConditionalRender condition={column.sortable}>
          <TableSortLabel
            active={Boolean(tableState.sorting?.[column.field])}
            direction={tableState.sorting?.[column.field]}
            onClick={() => updateTableStateSorting(column.field)}
          >
            <Typography variant="body2Bold">{t(column.headerTitle) as ReactNode}</Typography>
          </TableSortLabel>
          <Typography variant="body2Bold">{t(column.headerTitle) as ReactNode}</Typography>
        </ConditionalRender>
        {column.headerTip && (
          <Tooltip title={t(column.headerTip) as ReactNode} placement="bottom-end">
            <Icons.ErrorOutline sx={{ color: 'black' }} />
          </Tooltip>
        )}
      </Stack>
    </MuiTableCell>
  );
};

export default ThColumnCell;
