import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, buttonClasses, ConditionalRender, Icons, Popover, Typography } from '@egym/ui';
import PartnerConfigsSelectList from '../PartnerConfigsSelectList';
import { PartnerConfigsSelectProps } from './PartnerConfigsSelectProps';

const PartnerConfigsSelect: FC<PartnerConfigsSelectProps> = ({
  configs,
  onConfigSelect,
  selectedPartnerConfig,
  loading,
  openList,
  setOpenList,
}) => {
  const { t } = useTranslation();
  return (
    <ConditionalRender condition={Boolean(configs.length)}>
      <Popover
        onAnchorClick={() => setOpenList(true)}
        onClose={() => setOpenList(false)}
        open={openList}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button
          color="inherit"
          variant="text"
          sx={({ palette }) => ({
            color: openList ? palette.primary.main : 'common.black',
            width: 'fit-content',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'transparent',
              color: palette.primary.main,
            },
            [`.${buttonClasses.endIcon}`]: {
              ml: 1.5,
            },
          })}
          loading={loading}
          text={
            <Typography
              variant="body2Bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedPartnerConfig?.name ?? t('subHeader.selectPartner')}
            </Typography>
          }
          endIcon={openList ? <Icons.KeyboardArrowDown /> : <Icons.KeyboardArrowUp />}
          data-testid="sub-header-open-partner-configs-button"
        />
        <PartnerConfigsSelectList
          configs={configs}
          onConfigSelect={onConfigSelect}
          selectedConfigId={selectedPartnerConfig?.id}
          onClose={() => setOpenList(false)}
        />
      </Popover>
    </ConditionalRender>
  );
};

export default PartnerConfigsSelect;
