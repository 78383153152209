import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup';
import { REG_EXPS } from '@egym/utils';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (message) {
  return this.transform(value => value || null).matches(REG_EXPS.PHONE, message || 'Phone number is not valid');
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'extUrl', function (message) {
  return this.transform(value => value || null).matches(REG_EXPS.URL, message || 'Must be a valid URL');
});

declare module 'yup' {
  interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject>
    extends Yup.Schema<TType, TContext> {
    phone(message: string): StringSchema<TType, TContext>;

    extUrl(message: string): StringSchema<TType, TContext>;
  }
}

export default Yup;
