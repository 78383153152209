import { ConnectionLocationAddress } from '@types';

export const formatStreet = (address: ConnectionLocationAddress): string | undefined => {
  if (address.addressLine1 && !address.addressLine2) {
    return address.addressLine1;
  }
  if (address.addressLine1 && address.addressLine2) {
    return `${address.addressLine1} ${address.addressLine2}`;
  }

  return undefined;
};

export const formatCity = (address: ConnectionLocationAddress): string | undefined => {
  if (!address.postalCode && address.city) {
    return address.city;
  }
  if (address.postalCode && address.city) {
    return `${address.postalCode} ${address.city}`;
  }
  return undefined;
};

export const formatAddress = (address?: ConnectionLocationAddress): string => {
  if (address) {
    const values: string[] = [];

    const street = formatStreet(address);
    if (street) {
      values.push(street);
    }

    const city = formatCity(address);
    if (city) {
      values.push(city);
    }

    if (address.country) {
      values.push(address.country);
    }
    return values.length > 0 ? values.join(', ') : '-';
  }
  return '-';
};
