import { useQuery } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getModulesInfo, getUserInfo } from '@api';
import { sessionState } from '@globalState';
import { ModulesInfo, UserInfo } from '@types';
import { useErrorHandlers } from '../useErrorHandlers';

const useSessionInfo = () => {
  const setUserInfo = useSetRecoilState(sessionState.atoms.userInfo);
  const setModuleInfo = useSetRecoilState(sessionState.atoms.moduleInfo);
  const [partnerConfig, setPartnerConfig] = useRecoilState(sessionState.atoms.selectedPartnerConfig);
  const { onForbiddenError } = useErrorHandlers();
  const sessionInfo = useQuery(
    ['sessionInfo'],
    async () => {
      const userInfo = await getUserInfo();
      const modulesInfo = await getModulesInfo();
      return [userInfo, modulesInfo];
    },
    {
      select: ([userInfo, modulesInfo]) => [userInfo.data, modulesInfo.data] as [UserInfo, ModulesInfo],
      onError: err => {
        console.error('error getting user / module info, redirecting to login page', err);
        onForbiddenError();
      },
      onSuccess: ([userInfo, modulesInfo]: [UserInfo, ModulesInfo]) => {
        setUserInfo(userInfo);
        setModuleInfo(modulesInfo);
        if (!partnerConfig) {
          if (userInfo?.partnerConfig) {
            setPartnerConfig(userInfo.partnerConfig);
          } else {
            setPartnerConfig(null);
          }
        }
      },
    },
  );

  return {
    sessionInfo,
  };
};

export default useSessionInfo;
