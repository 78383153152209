import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLink, Container, Divider, Stack, Typography } from '@egym/ui';
import { ReactComponent as LogoIcon } from 'src/shared/svg/logo.svg';
import { footerHeightPx } from 'src/styles/constants';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container
      component="footer"
      maxWidth={false}
      disableGutters
      sx={{
        height: footerHeightPx,
        minWidth: theme => theme.breakpoints.values.lg,
        bgcolor: 'common.black',
        zIndex: 10,
        position: 'relative',
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ height: '100%', py: 2, boxSizing: 'border-box' }}
          >
            <Typography variant="body2" color="grey.500">
              {t('footer.copyrightText', { year: new Date().getFullYear() })}
            </Typography>
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              sx={{ borderColor: 'grey.500', mx: 2, height: '20px' }}
            />
            <BaseLink
              sx={{ textDecoration: 'none' }}
              href="https://egym.com/us/contact"
              target="_self"
              color="grey.600"
            >
              <Typography variant="body2" color="grey.500">
                {t('footer.contact')}
              </Typography>
            </BaseLink>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="body2" color="grey.500">
              {t('footer.poweredBy')}
            </Typography>
            <LogoIcon width="100px" />
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default Footer;
