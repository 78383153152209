import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton as MuiLoadableButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';

export { buttonClasses } from '@mui/material';

export type ButtonProps = {
  text: ReactNode;
};

type Props = ButtonProps & MuiLoadingButtonProps;

export const Button = forwardRef<HTMLButtonElement, Props>(({ text, type = 'button', ...rest }, ref) => {
  const { t } = useTranslation();
  return (
    <MuiLoadableButton type={type} {...rest} ref={ref}>
      {typeof text === 'string' ? (t(text) as ReactNode) : text}
    </MuiLoadableButton>
  );
});
