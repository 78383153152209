import React from 'react';
import { Outlet } from 'react-router';
import { Container, Box } from '@egym/ui';
import { Footer, Header } from '@components';
import { minPageHeight } from 'src/styles/constants';

const Layout: React.FC = () => {
  return (
    <Box>
      <Header />
      <Container maxWidth="lg" sx={{ pb: 5, minHeight: minPageHeight }}>
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
