import { useQuery } from 'react-query';
import { getPartnerConfigs } from '@api';
import { useErrorHandlers } from '../useErrorHandlers';

export const configsKeys = {
  all: ['configs'] as const,
  current: (id: number) => [...configsKeys.all, id] as const,
};

export const usePartnerConfigs = () => {
  const { onForbiddenError } = useErrorHandlers();
  const partnerConfigsQuery = useQuery(
    configsKeys.all,
    async () => {
      return getPartnerConfigs();
    },
    {
      enabled: false,
      select: ({ data }) => data,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );
  return {
    partnerConfigsQuery,
  };
};
