import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useEffectOnce, useUpdateEffect } from 'react-use';
import { colors, InputBase } from '@mui/material';
import { styled } from '@mui/system';
import { IconButton, Icons, TableState } from '@egym/ui';

type Props = {
  stateField: string;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  tableState: TableState;
  testIdPrefix?: string;
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  maxWidth: '335px',
  minHeight: '44px',
  borderRadius: '48px',
  alignItems: 'center',
  backgroundColor: colors.grey[200],
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  color: colors.grey[500],
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 4, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(6.5)})`,
    transition: (theme.transitions as any).create('width'),
    width: '100%',
  },
}));

const TableGlobalSearch: FC<Props> = ({ stateField, tableState, updateTableStateFilters, testIdPrefix }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState((tableState.filtering?.[stateField] as string) || '');

  const handleChange = useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const submit = useCallback(() => {
    if (inputValue.length >= 1) {
      updateTableStateFilters([stateField], inputValue);
    } else {
      updateTableStateFilters([stateField], null);
    }
  }, [stateField, inputValue, updateTableStateFilters]);

  const cancelSearch = useCallback(() => {
    setInputValue('');
  }, []);

  const [, cancelDebounce] = useDebounce(
    () => {
      submit();
    },
    1000,
    [inputValue],
  );

  useEffectOnce(() => {
    cancelDebounce();
  });

  useUpdateEffect(() => {
    if (!tableState.filtering?.[stateField] && inputValue) {
      cancelSearch();
    }
  }, [tableState.filtering?.[stateField]]);

  return (
    <Search>
      <SearchIconWrapper>
        <Icons.SearchOutlined />
      </SearchIconWrapper>
      <StyledInputBase
        endAdornment={
          inputValue && (
            <IconButton
              onClick={cancelSearch}
              sx={{
                fontSize: '22px',
                mr: 0.5,
                '&:hover': { backgroundColor: 'inherit' },
              }}
              edge="end"
              size="large"
              data-testid={`${testIdPrefix}table-global-search-cancel`}
            >
              <Icons.Cancel fontSize="inherit" color="action" />
            </IconButton>
          )
        }
        placeholder={t('common.label.search')}
        inputProps={{ 'aria-label': 'search' }}
        data-testid={`${testIdPrefix}table-global-search`}
        autoFocus
        value={inputValue}
        onChange={handleChange}
      />
    </Search>
  );
};

export default TableGlobalSearch;
