import { FC } from 'react';
import CreateUseCaseForm from './CreateUseCaseForm';
import useCreateUseCaseForm from './hooks/useCreateUseCaseForm';

const CreateUseCaseFormContainer: FC = () => {
  const result = useCreateUseCaseForm();

  return <CreateUseCaseForm {...result} />;
};

export default CreateUseCaseFormContainer;
