import { createApiRequest } from '@egym/api';
import { Paginated } from '@egym/types';
import {
  CreateUseCaseFormValues,
  IntegrationType,
  UseCase,
  UseCaseName,
  UseCaseStats,
  TestCase,
  HistoryItem,
} from '@types';

const BASE_USE_CASES_URL = 'integrations/usecases';
const USE_CASE_BY_ID_URL = `${BASE_USE_CASES_URL}/:usecaseId`;
const ACTIVE_USE_CASE_URL = `${BASE_USE_CASES_URL}/active`;
const FINISH_USE_CASE_URL = `${USE_CASE_BY_ID_URL}/finish`;
const TEST_CASE_BY_ID_URL = `${USE_CASE_BY_ID_URL}/testcases/:testcaseId`;
const REPEAT_TEST_CASE_BY_URL = `${TEST_CASE_BY_ID_URL}/repeat`;
const SKIP_TEST_CASE_BY_URL = `${TEST_CASE_BY_ID_URL}/skip`;
const GENERATE_TEST_CASE_DATA_BY_URL = `${TEST_CASE_BY_ID_URL}/generate`;
const USE_CASE_STATS_URL = `${BASE_USE_CASES_URL}/stats`;
const USE_CASE_HISTORY_URL = `${BASE_USE_CASES_URL}/history`;

export const getActiveUseCase = createApiRequest<
  UseCase,
  unknown,
  unknown,
  { type: IntegrationType; name: UseCaseName; partnerConfigId: number }
>(ACTIVE_USE_CASE_URL);

export const postNewUseCase = createApiRequest<
  UseCase,
  CreateUseCaseFormValues,
  unknown,
  {
    partnerConfigId: number;
  }
>(BASE_USE_CASES_URL, 'post');

export const postFinishUseCase = createApiRequest<
  unknown,
  unknown,
  {
    usecaseId: number;
  },
  { partnerConfigId: number }
>(FINISH_USE_CASE_URL, 'post');

export const postRepeatTestCase = createApiRequest<
  TestCase,
  unknown,
  {
    usecaseId: number;
    testcaseId: number;
  },
  { partnerConfigId: number }
>(REPEAT_TEST_CASE_BY_URL, 'post');

export const postSkipTestCase = createApiRequest<
  TestCase,
  unknown,
  {
    usecaseId: number;
    testcaseId: number;
  },
  { partnerConfigId: number }
>(SKIP_TEST_CASE_BY_URL, 'post');

export const getUseCaseStats = createApiRequest<
  UseCaseStats,
  unknown,
  unknown,
  {
    partnerConfigId: number;
  }
>(USE_CASE_STATS_URL);

export const getUseCaseHistory = createApiRequest<
  Paginated<HistoryItem>,
  unknown,
  unknown,
  { partnerConfigId: number }
>(USE_CASE_HISTORY_URL);

export const postGenerateTestCaseData = createApiRequest<
  TestCase,
  unknown,
  {
    usecaseId: number;
    testcaseId: number;
  },
  { valueType: string; partnerConfigId: number }
>(GENERATE_TEST_CASE_DATA_BY_URL, 'post');
