import React, { PropsWithChildren } from 'react';
import { Typography, Icons, SxProps, IconButton, Stack, Divider } from '@egym/ui';

type Props = {
  onClose?: () => void;
  isLoading?: boolean;
  wrapperSx?: SxProps;
} & PropsWithChildren;

export const DialogTitle: React.FC<Props> = ({ onClose, isLoading, children, wrapperSx }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={8}
      py={2}
      sx={{
        position: 'sticky',
        top: 0,
        bgcolor: 'common.white',
        zIndex: 10,
        borderBottom: '1px solid #d8d8d8',
        ...wrapperSx,
      }}
    >
      <Typography sx={{ my: 4 }} variant="h5" fontWeight="bold" data-testid="dialog-header">
        {children}
      </Typography>
      {onClose && (
        <Stack direction="row" spacing={4}>
          <Divider orientation="vertical" flexItem variant="fullWidth" />
          <IconButton
            data-testid="dialog-header-close"
            aria-label="close"
            onClick={onClose}
            size="small"
            disabled={isLoading}
          >
            <Icons.Close />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};
