import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useEffectOnce, useUpdateEffect } from 'react-use';
import { InputBase } from '@mui/material';
import { IconButton, Stack, Typography, TableColumn, TableState, Icons } from '@egym/ui';

type Props = {
  column: TableColumn;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  tableState: TableState;
};

const TableInputSearch: React.FC<Props> = ({ column, updateTableStateFilters, tableState }) => {
  const [showInput, setShowInput] = useState(tableState.filtering?.[column.field] || false);
  const [inputValue, setInputValue] = useState((tableState.filtering?.[column.field] as string) || '');
  const { t } = useTranslation();

  const handleChange = useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const submit = useCallback(() => {
    if (inputValue.length >= (column.filter?.filterProps?.inputProps.minLength ?? 2)) {
      updateTableStateFilters([column.field], inputValue);
    } else {
      updateTableStateFilters([column.field], null);
    }
  }, [column.field, column.filter, inputValue, updateTableStateFilters]);

  const cancelSearch = useCallback(() => {
    setInputValue('');
    setShowInput(false);
  }, []);

  const [, cancelDebounce] = useDebounce(
    () => {
      submit();
    },
    1000,
    [inputValue],
  );

  useEffectOnce(() => {
    cancelDebounce();
  });

  useUpdateEffect(() => {
    if (!tableState.filtering?.[column.field] && inputValue) {
      cancelSearch();
    }
  }, [tableState.filtering?.[column.field]]);

  return showInput ? (
    <InputBase
      endAdornment={
        <IconButton
          onClick={cancelSearch}
          sx={{ fontSize: '20px' }}
          edge="end"
          size="large"
          data-testid={`table-filter-${column.field}-search-cancel`}
        >
          <Icons.CancelOutlined fontSize="inherit" color="action" />
        </IconButton>
      }
      placeholder={t('common.label.search')}
      autoFocus
      value={inputValue}
      onChange={handleChange}
      {...column.filter?.filterProps}
      inputProps={{
        style: { padding: 0, fontSize: '14px' },
        minLength: 2,
        ...column.filter?.filterProps?.inputProps,
      }}
      data-testid={`table-filter-${column.field}-search-input`}
    />
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      sx={{ cursor: 'pointer' }}
      onClick={() => setShowInput(true)}
    >
      <IconButton
        sx={{ fontSize: '20px' }}
        edge="start"
        size="large"
        data-testid={`table-filter-${column.field}-search-button`}
      >
        <Icons.Search fontSize="inherit" color="action" />
      </IconButton>
      <Typography variant="body2">{t('common.label.search') as ReactNode}</Typography>
    </Stack>
  );
};

export default TableInputSearch;
