import { createApiRequest } from '@egym/api';
import { Paginated } from '@egym/types';
import { ConnectedLocationsStats, ConnectedLocationItem, ConnectedLocationDetails, ApiVersion } from '@types';

const BASE_LOCATIONS_URL = 'mms/locations';
const LOCATIONS_STATS_URL = `${BASE_LOCATIONS_URL}/stats`;
const LOCATION_DETAILS_URL = `${BASE_LOCATIONS_URL}/:id`;

export const getConnectedLocationsPage = createApiRequest<
  Paginated<ConnectedLocationItem>,
  unknown,
  unknown,
  { partnerConfigId: number }
>(BASE_LOCATIONS_URL);

export const getConnectedLocationsStats = createApiRequest<
  ConnectedLocationsStats,
  unknown,
  unknown,
  {
    partnerConfigId: number;
  }
>(LOCATIONS_STATS_URL);

export const getConnectedLocationDetails = createApiRequest<
  ConnectedLocationDetails,
  unknown,
  unknown,
  { partnerConfigId: number; apiVersion: ApiVersion }
>(LOCATION_DETAILS_URL);
