import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { useRouter } from '@egym/ui';
import { isGymsPage, isIntegrationPage } from '@constants';
import { sessionState } from '@globalState';
import { usePartnerConfigs } from '@hooks';
import { UsePartnerConfigsSelectResult } from '../../PartnerConfigsSelectProps';

const usePartnerConfigsSelect = (): UsePartnerConfigsSelectResult => {
  const { pathname } = useRouter();
  const [partnerConfig, setPartnerConfig] = useRecoilState(sessionState.atoms.selectedPartnerConfig);
  const [openList, setOpenList] = useState(false);

  const { partnerConfigsQuery } = usePartnerConfigs();

  const asyncResult = useAsync(async () => {
    const result = await partnerConfigsQuery.refetch();
    return result.data ?? [];
  });

  useEffect(() => {
    if (!partnerConfig && asyncResult?.value?.length) {
      setPartnerConfig(asyncResult.value[0]);
    }
  }, [partnerConfig, setPartnerConfig, asyncResult]);

  const configs = useMemo(
    () =>
      (asyncResult.loading ? [] : (asyncResult.value ?? [])).filter(
        it => (isIntegrationPage(pathname) && it.integrations) || (isGymsPage(pathname) && it.gyms),
      ),
    [asyncResult, pathname],
  );

  const selectedPartnerConfig = useMemo(() => {
    return configs.find(it => it.id === partnerConfig?.id);
  }, [configs, partnerConfig?.id]);

  const onConfigSelect = useCallback(
    (configId: number) => {
      if (configId !== selectedPartnerConfig?.id) {
        setPartnerConfig(configs.find(it => it.id === configId) ?? null);
      }
    },
    [configs, selectedPartnerConfig?.id, setPartnerConfig],
  );

  return {
    configs,
    selectedPartnerConfig,
    loading: asyncResult.loading,
    onConfigSelect,
    openList,
    setOpenList,
  };
};

export default usePartnerConfigsSelect;
