import { IntegrationType } from './integration';
import { TestCase } from './testcase';

export enum UseCaseName {
  MemberSync = 'MEMBER_SYNC',
  CheckinSync = 'CHECKIN_SYNC',
  TrainerTask = 'TRAINER_TASK',
  RfidSync = 'RFID_SYNC',
  ProductBooking = 'PRODUCT_BOOKING',
  MemberOnboarding = 'MEMBER_ONBOARDING',
  MemberAdmission = 'MEMBER_ADMISSION',
}

export type UseCaseNaming = {
  name: UseCaseName;
  type: IntegrationType;
  disabled?: boolean;
};

export type CreateUseCaseFormValues = {
  type: IntegrationType | null;
  name: UseCaseName | null;
};

export type UseCase = {
  id: number;
  name: UseCaseName;
  type: IntegrationType;
  active: boolean;
  testCases: TestCase[];
  context: Record<string, string>;
};

export type UseCaseStats = {
  [key in IntegrationType]: {
    name: UseCaseName;
    total: number;
    passed: number;
  }[];
};
