import { UserInfo } from '@types';

export const formatName = (userInfo: UserInfo): string => {
  if (!userInfo || !userInfo.firstName) {
    return '';
  }
  const firstName = userInfo.firstName;

  if (firstName.length > 10) {
    const lastName = !userInfo.lastName ? '' : userInfo.lastName[0];
    return (firstName[0] + lastName).toUpperCase();
  }
  return firstName;
};
