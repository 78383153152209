import React, { useMemo } from 'react';
import { FormControlProps } from '@mui/material';
import { FieldProps } from 'formik';
import { get } from 'lodash';
import { Option } from '@egym/types';
import { FormFieldWithLabel, SelectField, SelectProps, SxProps } from '@egym/ui';

type SelectFormFieldProps = {
  label?: string;
  name: string;
  wrapperSx?: SxProps;
  labelSx?: SxProps;
  fieldSx?: SxProps;
  viewModeFieldSx?: SxProps;
  isViewMode?: boolean;
  hiddenLabel?: boolean;
  options: Option[];
  markAsOptional?: boolean;
};

type Props = SelectProps & FormControlProps & FieldProps & SelectFormFieldProps;

export const SelectFormField: React.FC<Props> = React.memo(
  ({ wrapperSx, labelSx, fieldSx, viewModeFieldSx, field, form, label, options, markAsOptional, ...props }) => {
    const getByFieldName = useMemo(() => (obj: any) => get(obj, field.name), [field.name]);
    return (
      <FormFieldWithLabel
        wrapperSx={wrapperSx}
        labelSx={labelSx}
        label={label}
        hiddenLabel={props.hiddenLabel}
        id={props.id}
        isViewMode={props.isViewMode}
        markAsOptional={markAsOptional}
      >
        {({ fieldSx: labelFieldSx, viewModeFieldSx: labelViewModeFieldSx, helperTextSx: labelHelperTextSx }) => (
          <SelectField
            options={options}
            isViewMode={props.isViewMode}
            helperText={getByFieldName(form.touched) && getByFieldName(form.errors)}
            sx={fieldSx || labelFieldSx}
            viewModeSx={labelViewModeFieldSx}
            error={Boolean(getByFieldName(form.touched)) && Boolean(getByFieldName(form.errors))}
            FormHelperTextProps={{
              sx: labelHelperTextSx,
            }}
            label={!props.hiddenLabel && label}
            {...props}
            {...field}
          />
        )}
      </FormFieldWithLabel>
    );
  },
);
