import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from '@egym/ui';

export const OverflowTip: React.FC<
  Omit<TooltipProps, 'title' | 'children'> & {
    children?: ReactNode;
    title?: ReactNode | string;
  }
> = ({ title, children, sx, ...props }) => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef<HTMLDivElement>();

  const compareSize = () => {
    // @ts-ignore
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, [children]);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [children],
  );

  return (
    <Tooltip {...props} arrow={false} title={title ? <div>{title}</div> : children} disableHoverListener={!hoverStatus}>
      <div
        // @ts-ignore
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
