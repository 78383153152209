import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Option } from '@egym/types';
import { ConditionalRender, MenuItem, TextField, TextFieldProps, SxProps } from '@egym/ui';

type CustomProps = {
  options: Option[];
  viewModeSx?: SxProps;
  isViewMode?: boolean;
};

type Props = TextFieldProps & CustomProps;

export const SelectField: React.FC<Props> = ({ sx, viewModeSx, options, isViewMode, placeholder, ...rest }) => {
  const { t } = useTranslation();
  const selectedOption = useMemo<Option | undefined>(() => {
    return options.find(option => option.value === rest.value);
  }, [options, rest.value]);

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <ConditionalRender condition={isViewMode}>
      <Typography sx={viewModeSx} variant="body1">
        {selectedOption ? (t(selectedOption?.label) as ReactNode) : '-'}
      </Typography>
      <Stack sx={{ width: '160px', ...sx }}>
        <TextField
          select
          sx={{ width: '100%', '.select-item-description': { display: 'none' } }}
          {...rest}
          value={rest.value || ''}
          SelectProps={{
            displayEmpty: Boolean(placeholder),
            MenuProps: {
              MenuListProps: {
                // @ts-ignore
                ['data-testid']: `${rest.id}-select-items-list`,
              },
            },
            ...rest.SelectProps,
          }}
          InputLabelProps={{ sx: { lineHeight: 'normal' } }}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              <Typography variant="body1Bold">{t(placeholder) as ReactNode}</Typography>
            </MenuItem>
          )}
          {options.map(({ iconProps, icon: Icon, ...option }) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                {Icon && <Icon {...iconProps} />}
                {t(option.label, option.labelTProps) as ReactNode}
              </Stack>

              {option.description && (
                <Typography
                  className="select-item-description"
                  component="div"
                  variant="body2"
                  sx={{ color: 'grey.600', whiteSpace: 'initial' }}
                >
                  {t(option.description) as ReactNode}
                </Typography>
              )}
            </MenuItem>
          ))}
        </TextField>
        {selectedOption?.description && (
          <Typography
            variant="body2"
            component="div"
            sx={{
              mt: 3,
              color: rest.disabled ? 'action.disabled' : 'grey.600',
              whiteSpace: 'initial',
            }}
          >
            {t(selectedOption?.description) as ReactNode}
          </Typography>
        )}
      </Stack>
    </ConditionalRender>
  );
};
