import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { dateLocaleMap, localeState } from '@egym/ui';
import { convertDate, formatDate, toLocaleFormat } from '@egym/utils';

const useFormatDate = (locale?) => {
  const currentGlobalLocale = useRecoilValue(localeState.atoms.currentLocale);
  const localeFile = useMemo(
    () => dateLocaleMap[toLocaleFormat(locale || currentGlobalLocale)],
    [locale, currentGlobalLocale],
  );

  const convertTimezone = useCallback(
    (value, fromUTC = true) => {
      return convertDate(value, fromUTC, localeFile);
    },
    [localeFile],
  );

  const format = useCallback(
    (value, formatString = 'PPp', fromUTC = true) => {
      if (value) {
        return formatDate(value, formatString, fromUTC, localeFile);
      }
      return null;
    },
    [localeFile],
  );

  return {
    format,
    convertTimezone,
    localeFile,
  };
};

export default useFormatDate;
