// eslint-disable-next-line import/no-extraneous-dependencies
import download from 'downloadjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { compile } from 'path-to-regexp';
import { defaultPrefix } from '../axiosClient';

export const getFileFromUrl = (src, fileName = '', mimeType = ''): Promise<File> => {
  const fileNameResult = fileName || src.split('/')[src.split('/').length - 1];

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', src, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status >= 200 || this.status < 300) {
        const imgBlob = this.response;
        resolve(new File([imgBlob], fileNameResult, { ...imgBlob, type: mimeType || imgBlob.type }));
      }
    };
    xhr.onerror = reject;
    xhr.send();
  });
};

export const getTextFromSvgUrl = src => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', src, true);
    xhr.responseType = 'text';
    xhr.onload = function () {
      if (this.status >= 200 || this.status < 300) {
        const svgText = this.response;

        resolve(svgText);
      }
    };
    xhr.onerror = reject;
    xhr.send();
  });
};

export const getFileNameFromContentDisposition = disposition => {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
};

export const downloadURI = (uri, fileName = '') => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFileFromApi = (headers, blob, mimetype = 'text/csv', fileName = '') => {
  const contentDispositionFileName = getFileNameFromContentDisposition(headers['content-disposition']);
  download(blob, fileName || contentDispositionFileName, mimetype);
};

export const compileApiUrl = (url: string, urlParams?: Record<string, any>, urlPrefix?: string): string =>
  `${urlPrefix || defaultPrefix}${url.includes(':') ? compile(url)(urlParams) : url}`;
