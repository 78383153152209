import { useMemo } from 'react';
import { RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { ModulesInfo, UserInfo } from '@types';
import { useSessionInfo } from '../index';
import constructRoutes from './constructRoutes';

const convertToRoutes = (sessionInfo?: [UserInfo, ModulesInfo]): RouteObject[] => {
  if (sessionInfo && sessionInfo.length === 2) {
    return constructRoutes(sessionInfo[0], sessionInfo[1]);
  }
  return constructRoutes();
};

const useAppRoutes = () => {
  const { sessionInfo } = useSessionInfo();
  const routes = useMemo(() => (sessionInfo.isFetching ? [] : convertToRoutes(sessionInfo.data)), [sessionInfo]);
  const appRoutes = useRoutes(routes);
  return {
    appRoutes,
    loading: sessionInfo.isFetching,
  };
};

export default useAppRoutes;
