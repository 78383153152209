import { Components } from '@mui/material/styles';

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    size: 'medium',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: '48px',
    },
    text: {
      fontFamily: ['HelveticaNowDisplay-Regular', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    endIcon: {
      marginLeft: 'auto',
    },
    outlined: {
      letterSpacing: '0.5px',
    },
    contained: {
      letterSpacing: '0.5px',
    },
  },
};

export default MuiButton;
