import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { groupBy, kebabCase, toLower } from 'lodash';
import { useRecoilValue } from 'recoil';
import { BaseLink, Typography, Stack, Chip, chipClasses, Divider } from '@egym/ui';
import { getAllUseCasesNaming, pageUrls } from '@constants';
import { sessionState } from '@globalState';

type Props = {
  onMenuItemClick: () => void;
};

const MenuLink = styled(BaseLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'black',
  '&.active': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
})) as any;

const IntegrationsDrawerContent: FC<Props> = ({ onMenuItemClick }) => {
  const { t } = useTranslation();

  const availableUseCases = getAllUseCasesNaming();
  const partnerConfigId = useRecoilValue(sessionState.atoms.selectedPartnerConfig)?.id;
  const groupedUsecases = useMemo(() => Object.entries(groupBy(availableUseCases, 'type')), [availableUseCases]);

  return (
    <Stack
      sx={{ width: '100%', my: 10 }}
      spacing={5}
      data-testid="sub-header-integrations-drawer"
      onClick={onMenuItemClick}
    >
      <Stack direction="row" spacing={10}>
        <Typography variant="h6Bold">{t('integration.title_plural')}</Typography>
      </Stack>
      <Divider variant="middle" sx={{ width: '85px', borderColor: 'black' }} />
      <Stack direction="row" alignItems="flex-start" spacing={8}>
        <Stack width="216px" minWidth="216px" spacing={4}>
          <MenuLink
            component={NavLink}
            to={pageUrls.overview}
            data-testid="sub-header-integrations-drawer-overview-link"
          >
            <Typography variant="body1Medium">{t('subHeader.dashboard')}</Typography>
          </MenuLink>
          <MenuLink component={NavLink} to={pageUrls.history} data-testid="sub-header-integrations-drawer-history-link">
            <Typography variant="body1Medium">{t('subHeader.history')}</Typography>
          </MenuLink>
          <MenuLink component={NavLink} to={pageUrls.info} data-testid="sub-header-integrations-drawer-info-link">
            <Typography variant="body1Medium">{t('subHeader.howToUse')}</Typography>
          </MenuLink>
        </Stack>
        {groupedUsecases.map(([type, list]) => (
          <Stack key={type} width="216px" minWidth="216px" data-testid={`sub-header-integrations-drawer-type-${type}`}>
            <Stack spacing={4}>
              <Chip
                size="small"
                label={
                  <Typography variant="captionBold" color="black">
                    {t(`integration.type.${type}`)}
                  </Typography>
                }
                sx={{
                  borderRadius: '6px',
                  width: 'fit-content',
                  border: 'none',
                  [`.${chipClasses.label}`]: {
                    py: 1.25,
                    px: 2,
                  },
                }}
              />
              {list.map(useCase =>
                useCase.disabled || !partnerConfigId ? (
                  <Typography
                    data-testid={`sub-header-integrations-drawer-type-${type}-usecase-${useCase.name}-link-disabled`}
                    key={useCase.name}
                    variant="body1Medium"
                    sx={{
                      color: 'grey.500',
                    }}
                  >
                    {t(`usecase.name.${useCase.name}`)}
                  </Typography>
                ) : (
                  <MenuLink
                    key={useCase.name}
                    component={NavLink}
                    to={pageUrls.integrationUseCaseUrl({
                      type: toLower(kebabCase(type)),
                      usecase: toLower(kebabCase(useCase.name)),
                    })}
                    data-testid={`sub-header-integrations-drawer-type-${type}-usecase-${useCase.name}-link`}
                  >
                    {<Typography variant="body1Medium">{t(`usecase.name.${useCase.name}`)}</Typography>}
                  </MenuLink>
                ),
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default IntegrationsDrawerContent;
