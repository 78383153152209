import React, { forwardRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
import { Box, BoxProps, useSafeHtml } from '@egym/ui';
import { wrapperStyles } from './SafeHtml.styles';

export type SafeHtmlProps = {
  dirtyHtml: string;
  sanitizeOptions?: DOMPurify.Config;
} & BoxProps;

export const SafeHtml = forwardRef<any, SafeHtmlProps>(({ dirtyHtml, sanitizeOptions, sx, ...rest }, ref) => {
  const sanitize = useSafeHtml(sanitizeOptions);

  const boxSx = { ...wrapperStyles, ...sx };

  return (
    <Box ref={ref} component="div" {...rest} sx={boxSx} dangerouslySetInnerHTML={{ __html: sanitize(dirtyHtml) }} />
  );
});
