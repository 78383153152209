import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLink, Box, Container, Stack, Typography } from '@egym/ui';
import { ReactComponent as LogoIcon } from 'src/shared/svg/logo.svg';
import { headerHeight } from 'src/styles/constants';
import LoginInfo from './components/LoginInfo';
import SubHeader from './components/SubHeader';
import { HeaderProps } from './HeaderProps';

const Header: React.FC<HeaderProps> = ({ wrapperSx, userInfo, moduleInfo, logout }) => {
  const { t } = useTranslation();
  return (
    <Container
      maxWidth={false}
      disableGutters
      component="header"
      sx={{
        height: headerHeight,
        minWidth: theme => theme.breakpoints.values.lg,
        top: 0,
        zIndex: 10,
        position: 'relative',
        ...wrapperSx,
      }}
    >
      <Box sx={{ bgcolor: 'common.black', width: '100%' }}>
        <Container maxWidth="lg">
          <Stack sx={{ py: 2.5 }} direction="row" alignItems="center" justifyContent="space-between">
            <BaseLink sx={{ textDecoration: 'none' }} href={moduleInfo?.gyms ? '/gyms' : '/overview'} target="_self">
              <Stack direction="row" spacing={3.5} alignItems="center">
                <LogoIcon style={{ height: '60px' }} />
                <Typography color="common.white" fontWeight="bold" component="span">
                  {t('header.title')}
                </Typography>
              </Stack>
            </BaseLink>
            {userInfo && <LoginInfo userInfo={userInfo} logout={logout} />}
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: 'common.white',
          width: '100%',
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <SubHeader />
      </Box>
    </Container>
  );
};

export default Header;
