import { atom } from 'recoil';
import { ModulesInfo, PartnerConfig, UserInfo } from '@types';
import { sessionStorageObjectEffect } from './effects';

const keys = {
  principalInfo: 'user.info',
  moduleInfo: 'module.info',
  ownedGymId: 'user.ownedGymId',
  selectedPartnerConfig: 'user.selectedPartnerConfig',
};

const atoms = {
  userInfo: atom<UserInfo | undefined>({
    key: keys.principalInfo,
    default: undefined,
  }),
  moduleInfo: atom<ModulesInfo | undefined>({
    key: keys.moduleInfo,
    default: undefined,
  }),
  selectedPartnerConfig: atom<PartnerConfig | null>({
    key: keys.selectedPartnerConfig,
    default: null,
    effects_UNSTABLE: [sessionStorageObjectEffect(keys.selectedPartnerConfig)],
  }),
};

export const sessionState = {
  keys,
  atoms,
};
