import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Fade,
  Paper,
  Stack,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  Pagination as MuiTablePagination2,
  PaginationItem as MuiTablePaginationItem2,
  paginationItemClasses,
} from '@mui/material';
import { lighten } from '@mui/system/colorManipulator';
import { Loader, TableColumn, ConditionalRender } from '@egym/ui';
import TableBody from './components/TableBody';
import TableEmptyState from './components/TableEmptyState';
import TableGlobalSearch from './components/TableGlobalSearch';
import TableHead from './components/TableHead';
import { availableRowsPerPageOptions } from './config';
import { GroupedTableColumn, TableProps } from './TableProps';

const Table: React.FC<TableProps> = ({
  wrapperSx,
  paperSx,
  columns,
  rowsPaginated,
  checkboxSelectionProp,
  selectedRowIds,
  handleSelectAllClick,
  handleRowClick,
  totalRowsCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  noRowsLabel = 'common.label.noData',
  noRowsWithFiltersLabel = 'common.label.noDataWithFilters',
  size = 'small',
  headersCellSx,
  tdProps,
  renderSubComponent,
  totalColumnsCount,
  isSelectAllChecked,
  isSelectAllIndeterminate,
  showCheckAll = true,
  getIsCheckboxSelected,
  getIsCheckboxIndeterminate,
  indeterminateRowIds,
  expandAll,
  tableState,
  updateTableStateSorting,
  updateTableStateFilters,
  hasExternalPagination,
  hasFilterableColumns,
  hasSelectedFilters,
  hasData,
  isViewMode,
  testIdPrefix,
  simplePaging,
  globalSearch,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={5}>
      {hasData && globalSearch && (
        <TableGlobalSearch
          stateField={globalSearch}
          updateTableStateFilters={updateTableStateFilters}
          tableState={tableState}
          testIdPrefix={testIdPrefix}
        />
      )}
      <Paper
        sx={{
          width: '100%',
          borderRadius: '16px',
          border: 'none',
          overflow: 'hidden',
          ...paperSx,
        }}
        variant="outlined"
      >
        <MuiTableContainer
          sx={{
            borderRadius: 1,
            ...wrapperSx,
            boxShadow: 'none',
            position: 'relative',
          }}
        >
          {loading && (
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  bgcolor: 'action.disabled',
                  zIndex: 10,
                }}
              >
                <Loader />
              </Stack>
            </Fade>
          )}
          <MuiTable size={size} data-testid={`${testIdPrefix}table`}>
            <TableHead
              columns={columns}
              hasData={hasData}
              checkboxSelectionProp={checkboxSelectionProp}
              onSelectAllClick={handleSelectAllClick}
              totalRowsCount={totalRowsCount}
              renderSubComponent={renderSubComponent}
              isSelectAllChecked={isSelectAllChecked}
              isSelectAllIndeterminate={isSelectAllIndeterminate}
              showCheckAll={showCheckAll}
              tableState={tableState}
              updateTableStateSorting={updateTableStateSorting}
              hasFilterableColumns={hasFilterableColumns}
              updateTableStateFilters={updateTableStateFilters}
              headersCellSx={headersCellSx}
              isViewMode={isViewMode}
              testIdPrefix={testIdPrefix}
            />
            <ConditionalRender condition={!rowsPaginated.length}>
              <TableEmptyState
                noRowsLabel={noRowsLabel}
                noRowsWithFiltersLabel={noRowsWithFiltersLabel}
                totalColumnsCount={totalColumnsCount}
                hasSelectedFilters={hasSelectedFilters}
                testIdPrefix={testIdPrefix}
              />
              <TableBody
                columns={columns.flatMap(column => (column as GroupedTableColumn).children || [column as TableColumn])}
                rows={rowsPaginated}
                checkboxSelectionProp={checkboxSelectionProp}
                selectedRows={selectedRowIds}
                onRowClick={handleRowClick}
                tdProps={tdProps}
                renderSubComponent={renderSubComponent}
                totalColumnsCount={totalColumnsCount}
                getIsCheckboxSelected={getIsCheckboxSelected}
                getIsCheckboxIndeterminate={getIsCheckboxIndeterminate}
                indeterminateRowIds={indeterminateRowIds}
                expandAll={expandAll}
                isViewMode={isViewMode}
                testIdPrefix={testIdPrefix}
              />
            </ConditionalRender>
          </MuiTable>
        </MuiTableContainer>
        {(totalRowsCount > rowsPerPage || hasExternalPagination) && !!rowsPaginated.length && !simplePaging && (
          // @ts-ignore
          <MuiTablePagination
            rowsPerPageOptions={availableRowsPerPageOptions}
            SelectProps={{
              inputProps: {
                ['data-testid']: `${testIdPrefix}select-page-size`,
              },
              MenuProps: {
                MenuListProps: {
                  ['data-testid']: `${testIdPrefix}select-page-size-list`,
                },
              },
            }}
            nextIconButtonProps={{
              ['data-testid']: `${testIdPrefix}next`,
            }}
            backIconButtonProps={{
              ['data-testid']: `${testIdPrefix}previous`,
            }}
            component="div"
            data-testid={`${testIdPrefix}pagination`}
            count={totalRowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('common.label.rowsPerPage') as ReactNode}
            labelDisplayedRows={({ from, to, count }) =>
              t('common.label.paginationRows', { from, to, count }) as ReactNode
            }
          />
        )}
      </Paper>
      {(totalRowsCount > rowsPerPage || hasExternalPagination) && !!rowsPaginated.length && simplePaging && (
        <MuiTablePagination2
          color="primary"
          count={Math.ceil(totalRowsCount / rowsPerPage)}
          page={page + 1}
          onChange={(e, num) => handleChangePage(null, num - 1)}
          variant="outlined"
          sx={{
            mx: 'auto !important',
          }}
          renderItem={item => (
            <MuiTablePaginationItem2
              sx={({ palette }) => ({
                [`&.${paginationItemClasses.previousNext}`]: {
                  color: palette.common.white,
                  backgroundColor: palette.common.black,
                  ['&:hover']: {
                    backgroundColor: lighten(palette.common.black, 0.15),
                  },
                },
              })}
              {...item}
              data-testid={`${testIdPrefix}table-pagination-item-${item.type === 'page' ? item.page : item.type}`}
            />
          )}
          data-testid={`${testIdPrefix}table-pagination`}
        />
      )}
    </Stack>
  );
};

export default Table;
