import React from 'react';
import { Navigate, RouteObject } from 'react-router';
import { Layout } from '@components';
import { pageUrls } from '@constants';
import { ModulesInfo, UserInfo } from '@types';

const OverviewPage = React.lazy(
  () => import(/* webpackChunkName: "IntegrationsOverviewPage" */ 'src/pages/integrations/OverviewPage'),
);
const UseCasePage = React.lazy(
  () => import(/* webpackChunkName: "UseCasePage" */ 'src/pages/integrations/UseCasePage'),
);
const HistoryPage = React.lazy(
  () => import(/* webpackChunkName: "IntegrationsHistoryPage" */ 'src/pages/integrations/HistoryPage'),
);
const HowToPage = React.lazy(
  () => import(/* webpackChunkName: "IntegrationsHowToPage" */ 'src/pages/integrations/HowToPage'),
);
const LocationsListPage = React.lazy(
  () => import(/* webpackChunkName: "LocationsListPage" */ 'src/pages/locations/LocationsListPage'),
);
const LocationDetailsPage = React.lazy(
  () => import(/* webpackChunkName: "LocationDetailsPage" */ 'src/pages/locations/LocationDetailsPage'),
);
const LoginPage = React.lazy(() => import(/* webpackChunkName: "LoginPage" */ 'src/pages/LoginPage'));

const constructRoutes = (userInfo?: UserInfo, modulesInfo?: ModulesInfo): RouteObject[] => [
  {
    path: pageUrls.login,
    element: Boolean(userInfo) ? <Navigate to="/" replace /> : <LoginPage />,
  },
  {
    path: '/',
    element: Boolean(userInfo) ? <Layout /> : <Navigate to="login" />,
    children: [
      ...(modulesInfo?.sandbox
        ? [
            { path: pageUrls.overview, element: <OverviewPage /> },
            { path: pageUrls.integrationUseCaseUrl(), element: <UseCasePage /> },
            { path: pageUrls.history, element: <HistoryPage /> },
            { path: pageUrls.info, element: <HowToPage /> },
          ]
        : []),
      ...(modulesInfo?.gyms
        ? [
            { path: pageUrls.locationsList, element: <LocationsListPage /> },
            { path: pageUrls.locationsDetailsUrl(), element: <LocationDetailsPage /> },
          ]
        : []),
      {
        path: '',
        element:
          modulesInfo?.gyms && (!userInfo?.partnerConfig || userInfo?.partnerConfig?.gyms) ? (
            <Navigate to={pageUrls.locationsList} />
          ) : (
            <Navigate to={pageUrls.overview} />
          ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

export default constructRoutes;
