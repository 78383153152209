import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, TableRenderCellParams } from '@egym/ui';

const RESULT_STYLES = {
  PASSED: { color: 'success', severity: 'success', styles: { bgcolor: 'rgba(23, 132, 59, 0.1)' } },
  FAILED: { color: 'error', severity: 'error', styles: { bgcolor: 'rgba(217, 40, 69, 0.1)' } },
  SKIPPED: { color: 'default', styles: { bgcolor: 'rgba(0, 0, 0, 0.1)' } },
};

const TestCaseResultCell: FC<TableRenderCellParams<any>> = ({ value }) => {
  const { t } = useTranslation();
  return RESULT_STYLES[value] ? (
    <Chip
      label={t(`testcase.status.${value}`)}
      color={RESULT_STYLES[value].color}
      sx={{
        borderRadius: '6px',
        border: 'none',
        height: '24px',
        textTransform: 'uppercase',
      }}
    />
  ) : null;
};

export default TestCaseResultCell;
