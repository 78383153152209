import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLink, ConditionalRender, ConditionTooltip, Icons, Stack, Typography } from '@egym/ui';

type Props = {
  value?: string;
  copyOnClick?: boolean;
  fallbackKey: string;
  testid?: string;
};

const CopyValueField: FC<Props> = ({ value, fallbackKey, testid, copyOnClick = true }) => {
  const { t } = useTranslation();

  const onCopyContentClick = useCallback(() => {
    navigator.clipboard.writeText(value ?? '');
  }, [value]);

  return (
    <ConditionalRender condition={copyOnClick && Boolean(value)}>
      <ConditionTooltip
        onClick={onCopyContentClick}
        arrow
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icons.CheckCircleOutline fontSize="small" />
            <Typography color="white" sx={{ textAlign: 'left' }}>
              {t('common.label.copied')}
            </Typography>
          </Stack>
        }
      >
        <BaseLink
          component="button"
          variant="body2Medium"
          type="button"
          color="text.primary"
          sx={{ textAlign: 'left', textDecoration: 'none', textWrap: 'nowrap' }}
          data-testid={testid ? `${testid}-copy-value` : undefined}
        >
          {value}
        </BaseLink>
      </ConditionTooltip>

      <Typography
        variant="body2Medium"
        sx={{ textAlign: 'left' }}
        data-testid={testid ? `${testid}-no-copy` : undefined}
      >
        {Boolean(value) ? value : t(fallbackKey)}
      </Typography>
    </ConditionalRender>
  );
};

export default CopyValueField;
