import React from 'react';
import { SxProps } from '@mui/system';
import { LoaderProps, Loader, Box, Container } from '@egym/ui';

type Props = {
  loader?: LoaderProps;
  wrapperSx?: SxProps;
};

export const LoadingContainer: React.FC<Props> = ({ loader, wrapperSx }) => {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 10,
          width: '100%',
          ...wrapperSx,
        }}
      >
        <Loader {...loader} />
      </Box>
    </Container>
  );
};
