import React, { Suspense } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useRecoilValue } from 'recoil';
import {
  Backdrop,
  ConditionalRender,
  ConfirmDialog,
  Container,
  ContentDialog,
  dateLocaleMap,
  FormDialog,
  LoadingContainer,
  localeState,
  Snackbar,
} from '@egym/ui';
import { uiState } from '@globalState';
import { useAppRoutes } from '@hooks';

const Root: React.FC = () => {
  const currentLocale = useRecoilValue(localeState.atoms.currentLocale);
  const backdrop = useRecoilValue(uiState.atoms.backdrop);
  const { appRoutes, loading } = useAppRoutes();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocaleMap[currentLocale]}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          minWidth: theme => theme.breakpoints.values.lg,
          backgroundColor: theme => theme.palette.background.default,
        }}
      >
        <Suspense fallback={<>...</>}>
          <ConditionalRender condition={loading}>
            <LoadingContainer wrapperSx={{ height: '100%', mt: '25%' }} />
            {appRoutes}
          </ConditionalRender>
        </Suspense>
      </Container>
      <Snackbar />
      <FormDialog />
      <ContentDialog />
      <ConfirmDialog />
      <Backdrop open={backdrop} sx={{ zIndex: 2 }} />
    </LocalizationProvider>
  );
};

export default Root;
