import { IntegrationType, UseCaseNaming, UseCaseName } from '@types';

export const platformUseCases: UseCaseNaming[] = [
  {
    name: UseCaseName.MemberSync,
    type: IntegrationType.Platform,
    disabled: false,
  },
];

export const workoutUseCases: UseCaseNaming[] = [
  {
    name: UseCaseName.RfidSync,
    type: IntegrationType.Workout,
    disabled: false,
  },
  {
    name: UseCaseName.CheckinSync,
    type: IntegrationType.Workout,
    disabled: false,
  },
  {
    name: UseCaseName.ProductBooking,
    type: IntegrationType.Workout,
    disabled: false,
  },
];

export const digitalUseCases: UseCaseNaming[] = [
  {
    name: UseCaseName.TrainerTask,
    type: IntegrationType.Digital,
    disabled: false,
  },
];

export const wellpassUseCases: UseCaseNaming[] = [
  {
    name: UseCaseName.MemberOnboarding,
    type: IntegrationType.Wellpass,
    disabled: false,
  },
  {
    name: UseCaseName.MemberAdmission,
    type: IntegrationType.Wellpass,
    disabled: false,
  },
];

export const getAllUseCasesNaming = (type?: IntegrationType): UseCaseNaming[] => {
  if (!type) {
    return [...platformUseCases, ...workoutUseCases, ...wellpassUseCases, ...digitalUseCases];
  }

  switch (type) {
    case IntegrationType.Platform:
      return platformUseCases;
    case IntegrationType.Workout:
      return workoutUseCases;
    case IntegrationType.Digital:
      return digitalUseCases;
    case IntegrationType.Wellpass:
      return wellpassUseCases;
    default:
      return [];
  }
};
