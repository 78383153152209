import { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';
import useRouter from '../useRouter';
import { confirmDialogGlobalState } from './state';
import { ConfirmDialogConfig } from './types';

const useConfirmDialog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogConfig, setDialogConfig] = useRecoilState(confirmDialogGlobalState.atoms.confirmDialogConfig);
  const { pathname } = useRouter();
  const isOpen = useMemo(() => Boolean(dialogConfig?.open), [dialogConfig]);

  const openConfirmDialog = useCallback(
    (config: ConfirmDialogConfig) => {
      setDialogConfig({
        shouldCloseOnPathnameChange: true,
        ...config,
        text: typeof config.text === 'string' ? [{ text: config.text }] : config.text || undefined,
        open: true,
      });
    },
    [setDialogConfig],
  );

  const openSaveChangesDialog = useCallback(
    (config: ConfirmDialogConfig) => {
      openConfirmDialog({
        title: 'common.label.saveChangesDialog.title',
        text: 'common.label.saveChangesDialog.description',
        cancelText: 'common.label.dontSave',
        confirmText: 'common.label.save',
        ...config,
      });
    },
    [openConfirmDialog],
  );

  const showCancelButton = useMemo(() => {
    return dialogConfig?.showCancelButton || dialogConfig?.onClose || dialogConfig?.showCancelButton === undefined;
  }, [dialogConfig]);

  const closeConfirmDialog = useCallback(() => {
    if (dialogConfig?.onClose) {
      dialogConfig.onClose();
    }
    setDialogConfig(prev => ({ ...prev, open: false }));
  }, [dialogConfig, setDialogConfig]);

  const cancelConfirmDialog = useCallback(() => {
    if (dialogConfig?.onCancel) {
      dialogConfig.onCancel();
    }
    setDialogConfig(prev => ({ ...prev, open: false }));
  }, [dialogConfig, setDialogConfig]);

  const confirm = useCallback(async () => {
    if (dialogConfig?.onConfirm) {
      try {
        setIsLoading(true);
        await dialogConfig.onConfirm();
        setDialogConfig(prev => ({ ...prev, open: false }));
      } finally {
        setIsLoading(false);
      }
    }
  }, [dialogConfig, setDialogConfig]);

  useUpdateEffect(() => {
    if (dialogConfig?.shouldCloseOnPathnameChange) {
      setDialogConfig(prev => ({
        ...prev,
        open: false,
      }));
    }
  }, [pathname]);

  return {
    isOpen,
    dialogConfig,
    openConfirmDialog,
    closeConfirmDialog,
    cancelConfirmDialog,
    confirm,
    showCancelButton,
    isLoading,
    openSaveChangesDialog,
  };
};

export default useConfirmDialog;
