import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { Button, Icons, Popover, TableColumn, TableState, IconButton, Stack } from '@egym/ui';
import useFormatDate from 'src/packages/ui/hooks/useFormatDate';
import TableFilterDatesRangePopoverContent from './TableFilterDatesRangePopoverContent';

type Props = {
  column: TableColumn;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  tableState: TableState;
};

const TableFilterDatesRange: React.FC<Props> = ({ column, updateTableStateFilters, tableState }) => {
  const { t } = useTranslation();
  const { format } = useFormatDate();

  const [fromDateValue, setFromDateValue] = useState<Date | null>(null);
  const [toDateValue, setToDateValue] = useState<Date | null>(null);

  const formattedFromDate = useMemo(() => {
    return fromDateValue ? format(fromDateValue, 'PP') : null;
  }, [format, fromDateValue]);

  const formattedToDate = useMemo(() => {
    return toDateValue ? format(toDateValue, 'PP') : null;
  }, [format, toDateValue]);

  const viewValue = useMemo(() => {
    if (fromDateValue && !toDateValue) return `${t('common.label.after')} ${formattedFromDate}`;
    if (toDateValue && !fromDateValue) return `${t('common.label.before')} ${formattedToDate}`;
    if (fromDateValue && toDateValue) {
      if (isSameDay(fromDateValue, toDateValue)) return formattedFromDate;
      return `${formattedFromDate} - ${formattedToDate}`;
    }
    return null;
  }, [fromDateValue, toDateValue, formattedFromDate, formattedToDate, t]);

  const hasSelectedValue = useMemo(() => {
    return fromDateValue || toDateValue;
  }, [fromDateValue, toDateValue]);

  const onCancel = useCallback(e => {
    e.stopPropagation();
    setFromDateValue(null);
    setToDateValue(null);
  }, []);

  const onConfirm = useCallback(() => {
    updateTableStateFilters([`${column.field}After`, `${column.field}Before`], [fromDateValue, toDateValue]);
  }, [fromDateValue, toDateValue, updateTableStateFilters, column.field]);

  useUpdateEffect(() => {
    onConfirm();
  }, [fromDateValue, toDateValue]);

  useUpdateEffect(() => {
    if (!tableState.filtering?.[`${column.field}After`] && fromDateValue) {
      setFromDateValue(null);
    }
  }, [tableState.filtering?.[`${column.field}After`]]);

  useUpdateEffect(() => {
    if (!tableState.filtering?.[`${column.field}Before`] && toDateValue) {
      setToDateValue(null);
    }
  }, [tableState.filtering?.[`${column.field}Before`]]);

  const onSetFromDate = useCallback((date: Date | null) => {
    if (date) {
      setFromDateValue(startOfDay(date));
    } else {
      setFromDateValue(date);
    }
  }, []);

  const onSetToDate = useCallback((date: Date | null) => {
    if (date) {
      setToDateValue(endOfDay(date));
    } else {
      setToDateValue(null);
    }
  }, []);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Stack display="inline-flex" direction="row" alignItems="center">
        <Button
          variant="text"
          type="button"
          color="inherit"
          text={
            <Stack direction="row" alignItems="center">
              {hasSelectedValue ? viewValue : (t('common.label.select') as ReactNode)}
              <Icons.KeyboardArrowDown />
            </Stack>
          }
          sx={{ px: 0, py: 1.25, fontWeight: 400, textTransform: 'capitalize' }}
        />
        {hasSelectedValue && (
          <IconButton sx={{ fontSize: '20px' }} edge="end" onClick={onCancel} size="large">
            <Icons.CancelOutlined fontSize="inherit" color="action" />
          </IconButton>
        )}
      </Stack>
      <TableFilterDatesRangePopoverContent
        fromDateValue={fromDateValue}
        onSetFromDate={onSetFromDate}
        toDateValue={toDateValue}
        onSetToDate={onSetToDate}
      />
    </Popover>
  );
};

export default TableFilterDatesRange;
