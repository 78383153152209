import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  BlackButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikScrollToError,
  useFormDialog,
} from '@egym/ui';

export const FormDialog = () => {
  const { t } = useTranslation();

  const { isOpen, formDialogConfig, closeFormDialog, showCancelButton, showConfirmButton, maxWidth } = useFormDialog();

  const FormComponent = formDialogConfig?.formComponent;
  const ActionsComponent = formDialogConfig?.actionsComponent;

  return FormComponent && formDialogConfig?.formProps ? (
    <Dialog
      open={isOpen}
      onClose={closeFormDialog}
      fullWidth
      maxWidth={maxWidth}
      PaperProps={{ sx: { borderRadius: '24px' } }}
      {...formDialogConfig?.muiDialogProps}
    >
      <Formik {...formDialogConfig.formProps}>
        {({ values, isSubmitting }) => {
          return (
            <Form>
              {formDialogConfig?.title && (
                <DialogTitle onClose={closeFormDialog} isLoading={isSubmitting}>
                  {t(formDialogConfig?.title, formDialogConfig?.titleParams) as ReactNode}
                </DialogTitle>
              )}
              <DialogContent data-testid="dialog-content" sx={{ py: 4, px: 8 }} {...formDialogConfig?.contentProps}>
                <FormikScrollToError>
                  <FormComponent {...values} {...formDialogConfig.formComponentProps} />
                </FormikScrollToError>
              </DialogContent>
              {(showCancelButton || (!formDialogConfig.formComponentProps?.isViewMode && showConfirmButton)) && (
                <DialogActions
                  sx={{
                    pt: 5,
                    pb: 10,
                    px: 8,
                    justifyContent: ActionsComponent ? 'space-between' : 'right',
                  }}
                  disableSpacing
                >
                  {ActionsComponent && <ActionsComponent {...values} />}
                  <Box>
                    {showCancelButton && (
                      <BlackButton
                        variant="outlined"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={closeFormDialog}
                        text={t(formDialogConfig?.cancelText || 'common.label.cancel') as ReactNode}
                        sx={{ minWidth: '100px' }}
                        {...formDialogConfig?.cancelButtonProps}
                        data-testid="dialog-canel-button"
                      />
                    )}
                    {!formDialogConfig.formComponentProps?.isViewMode && showConfirmButton && (
                      <BlackButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        text={t(formDialogConfig?.confirmText || 'common.label.yes') as ReactNode}
                        sx={{ ml: 4, minWidth: '100px' }}
                        loading={isSubmitting}
                        {...formDialogConfig?.confirmButtonProps}
                        data-testid="dialog-confirm-button"
                      />
                    )}
                  </Box>
                </DialogActions>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  ) : null;
};
