import React from 'react';
import { IconButton, Typography, BaseLink, Stack, SxProps, colors } from '@egym/ui';
import { formatName } from '@helpers';
import { SignOutIcon, UserIcon } from '@svg';
import { UserInfo } from '@types';

const LoginInfo: React.FC<{
  userInfo: UserInfo;
  logout: () => void;
  wrapperSx?: SxProps;
}> = ({ userInfo, logout, wrapperSx }) => {
  const profileSettingsUrl = `${window.MONOLITH_URL}/app/user/${userInfo.userId}/profile/settings/user-data`;
  const name = formatName(userInfo);
  return (
    <Stack direction="row" spacing={4} sx={{ mt: 1.5, ...wrapperSx }}>
      <BaseLink
        sx={{
          textDecoration: 'none',
          color: 'white',
          '&:hover': { textDecoration: 'underline', color: colors.grey[400] },
        }}
        href={profileSettingsUrl}
      >
        <Stack direction="row" alignItems="center">
          {userInfo.profileImageUrl ? (
            <img
              className="rounded-circle"
              src={userInfo.profileImageUrl}
              title={userInfo.firstName}
              style={{ width: '40px' }}
              alt=""
            />
          ) : (
            <UserIcon />
          )}
          <Typography sx={{ mx: 2 }} fontWeight="bold" component="span" data-testid="current-user-name">
            {name}
          </Typography>
        </Stack>
      </BaseLink>
      <IconButton
        id="logout-link"
        title="logout"
        onClick={logout}
        sx={{
          background: 'none',
          border: 'none',
          p: 0,
          cursor: 'pointer',
        }}
      >
        <SignOutIcon />
      </IconButton>
    </Stack>
  );
};

export default LoginInfo;
