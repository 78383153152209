import React, { useCallback } from 'react';
import { TableCell as MuiTableCell } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { get } from 'lodash';
import { TableColumn } from '@egym/ui';

type Props = {
  column: TableColumn;
  row: any;
  rowIndex: number;
  testIdPrefix: string;
  tdProps?: Record<string, any>;
  isViewMode?: boolean;
  wrappedSx?: SystemStyleObject;
};

const TableBodyCell: React.FC<Props> = ({ column, row, rowIndex, testIdPrefix, tdProps, isViewMode, wrappedSx }) => {
  const getByFieldName = useCallback((obj: any) => get(obj, column.field), [column.field]);
  const rawValue = getByFieldName(row);
  const value = column.accessor ? column.accessor({ value: rawValue }) : rawValue;
  const testIdValue = column.testIdAccessor
    ? column.testIdAccessor({
        value: rawValue,
        row,
      })
    : undefined;
  const bodyCellSx =
    typeof column.bodyCellSx === 'function'
      ? column.bodyCellSx({
          row,
          value,
        })
      : column.bodyCellSx;
  return (
    <MuiTableCell
      variant="body"
      scope="row"
      width={column.width}
      sx={{ ...wrappedSx, ...bodyCellSx }}
      align={column.numeric ? 'right' : 'left'}
      data-testid={`${testIdPrefix}table-body-cell-${column.field}-${rowIndex}`}
      data-testval={testIdValue}
    >
      {column.renderCell
        ? column.renderCell({
            row,
            value,
            rawValue,
            tdProps: { ...tdProps, ...column.tdProps },
            isViewMode,
          })
        : value || '-'}
    </MuiTableCell>
  );
};

export default TableBodyCell;
