import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, useTheme } from '@egym/ui';
import i18n from 'src/i18n';
import Root from 'src/pages/Root';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

const App: React.FC = () => {
  const { theme } = useTheme();

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<span />}>
                <RecoilRoot>
                  <Root />
                  <ReactQueryDevtools initialIsOpen={false} />
                </RecoilRoot>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
