import { atom } from 'recoil';

const keys = {
  backdrop: 'ui.backdrop',
};

const atoms = {
  backdrop: atom<boolean>({
    key: keys.backdrop,
    default: false,
  }),
};

export const uiState = {
  keys,
  atoms,
};
