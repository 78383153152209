import { generatePath } from 'react-router';
import { ApiVersion } from '@types';

const getUseCaseDetailsUrl = url => (params?: { type?: string; usecase?: string }) => {
  return params ? generatePath(url, params) : url;
};

const getLocationDetailsUrl = url => (params?: { apiVersion: ApiVersion; id: number }) => {
  return params
    ? generatePath(url, {
        apiVersion: params.apiVersion,
        id: params.id.toString(),
      })
    : url;
};

export const pageUrls = {
  login: '/login',
  overview: '/overview',
  usecases: '/integrations',
  history: '/history',
  info: '/info',
  locationsList: '/locations',

  get integrationUseCaseUrl() {
    return getUseCaseDetailsUrl(`${this.usecases}/:type/:usecase`);
  },

  get locationsDetailsUrl() {
    return getLocationDetailsUrl(`${this.locationsList}/:apiVersion/:id`);
  },
};

export const isIntegrationPage = (path: string): boolean => {
  return [pageUrls.overview, pageUrls.usecases, pageUrls.history, pageUrls.info].some(it => path.includes(it));
};

export const isGymsPage = (path: string): boolean => {
  return path.includes(pageUrls.locationsList);
};
