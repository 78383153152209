// https://mui.com/material-ui/react-tooltip/
import { Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

export { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
export type { TooltipProps } from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip arrow {...props} classes={{ popper: className }} />
))(({ theme, sx }) => ({
  ...sx,
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}`]: {
    left: '-6px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    borderRadius: '12px',
    fontSize: (theme.typography as any).pxToRem(14),
    lineHeight: '18px',
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}.${tooltipClasses.tooltip}`]: {
    marginRight: '-10px',
  },
}));
