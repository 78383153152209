import React, { useCallback, useRef } from 'react';
import { Popover as MuiPopover, PopoverProps } from '@mui/material';
import { Box, ConditionalRender, SxProps, Typography } from '@egym/ui';

type Props = Partial<PopoverProps> & {
  text?: string;
  textBoxSx?: SxProps;
  disabled?: boolean;
  onAnchorClick?: () => void;
  onClose?: () => void;
};

export const Popover: React.FC<Props> = React.memo(
  ({ children, text, textBoxSx, disabled, onAnchorClick, onClose, ...rest }) => {
    const childrenArray = React.Children.toArray(children);
    const popoverActions = useRef(null);
    const anchorRef = useRef(null);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleAnchorClick = useCallback(
      event => {
        setAnchorEl(event.currentTarget);

        if (onAnchorClick) onAnchorClick();
      },
      [onAnchorClick],
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);

      if (onClose) onClose();
    }, [onClose]);

    const open = Boolean(anchorEl);

    const anchor =
      React.isValidElement<any>(childrenArray[0]) &&
      React.cloneElement<any>(childrenArray[0], {
        text:
          typeof childrenArray[0].props.text === 'function'
            ? childrenArray[0].props.text(open)
            : childrenArray[0].props.text,
        ref: anchorRef,
        onClick: handleAnchorClick,
      });

    const popoverContent =
      React.isValidElement<any>(childrenArray[1]) &&
      React.cloneElement<any>(childrenArray[1], { handleClose, popoverActions, popoverOpen: open });

    if (!childrenArray.length) return null;

    return (
      <>
        {anchor}
        <MuiPopover
          action={popoverActions}
          open={!disabled && open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...rest}
        >
          <ConditionalRender condition={Boolean(text && !popoverContent)}>
            <Box sx={{ maxWidth: '500px', p: 4, ...textBoxSx }}>
              <Typography component="p" variant="body2">
                {text}
              </Typography>
            </Box>
            {popoverContent}
          </ConditionalRender>
        </MuiPopover>
      </>
    );
  },
);
