import { FC } from 'react';
import usePartnerConfigsSelect from './hooks/useIntegrationPartnerConfigsSelect';
import PartnerConfigsSelect from './PartnerConfigsSelect';

const PartnerConfigsSelectContainer: FC = () => {
  const result = usePartnerConfigsSelect();

  return <PartnerConfigsSelect {...result} />;
};

export default PartnerConfigsSelectContainer;
