import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { uniq } from 'lodash';
import { getAllUseCasesNaming } from '@constants';
import { CreateUseCaseFormValues } from '@types';
import { UseCreateUseCaseFormResultProps } from '../../CreateUseCaseFormProps';

const useCreateUseCaseForm = (): UseCreateUseCaseFormResultProps => {
  const { values } = useFormikContext<CreateUseCaseFormValues>();

  const integrationTypeOptions = useMemo(
    () =>
      uniq(
        getAllUseCasesNaming()
          .filter(it => !it.disabled)
          .map(it => it.type),
      ).map(value => ({ value, label: `integration.type.${value}` })),
    [],
  );

  const useCaseNameOptions = useMemo(
    () =>
      values.type
        ? getAllUseCasesNaming(values.type)
            .filter(it => !it.disabled)
            .map(it => ({
              value: it.name,
              label: `usecase.name.${it.name}`,
            }))
        : [],
    [values.type],
  );

  return {
    integrationTypeOptions,
    useCaseNameOptions,
  };
};

export default useCreateUseCaseForm;
