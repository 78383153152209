import { FC } from 'react';
import useSubHeader from './hooks/useSubHeader';
import SubHeader from './SubHeader';

const SubHeaderContainer: FC = () => {
  const result = useSubHeader();

  return <SubHeader {...result} />;
};

export default SubHeaderContainer;
