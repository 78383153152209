import { createApiRequest } from '@egym/api';
import { LoginRequest, UserInfo } from '@types';

const USER_INFO_URL = 'users/current';
const LOGIN_URL = 'login';
const LOGOUT_URL = 'logout';

export const getUserInfo = createApiRequest<UserInfo>(USER_INFO_URL);

export const postLogin = createApiRequest<unknown, LoginRequest>(LOGIN_URL, 'post');

export const postLogout = createApiRequest(LOGOUT_URL, 'post');
